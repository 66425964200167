import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Divider } from '@mui/material';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SimpleDialog({ title, content, action, open, onClose, ...other }) {
  return (
    <Dialog 
        fullWidth
        maxWidth="xs" 
        keepMounted 
        open={open} 
        onClose={onClose}
        TransitionComponent={Transition}
        {...other}
    >
      <DialogTitle sx={{ pb: 0, pt: 1 }}>{title}</DialogTitle>
      <Divider sx={{ mb: 1 }} />

      <DialogContent sx={{ py: 0 }}> {content} </DialogContent>

      <DialogActions>
        {action}
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node,
};
