// FIXME: udpate this to include all the routes for realix

import { Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import FullscreenLayout from 'src/layouts/fullscreen';
// import MainWithoutFooterLayout from 'src/layouts/main/index-without-footer';

// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { HomePage, LeadersHomePage, CoachesHomePage, mainRoutes, CaseSimulationDetailPage, OnboardingFormPage } from './main';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    {
      path: '/coaches',
      element: (
        <MainLayout>
          <CoachesHomePage />
        </MainLayout>
      ),
    },

    {
      path: '/leaders',
      element: (
        <MainLayout>
          <LeadersHomePage />
        </MainLayout>
      ),
    },

    {
      path: '/case-simulation-detail',
      element: <CaseSimulationDetailPage />,
      children: [
        { path: ':id', element: <CaseSimulationDetailPage /> },
      ]
    },

    {
      path: '/onboarding-form',
      element: (
        <SimpleLayout disabledLink isOnboarding>
          <OnboardingFormPage />
        </SimpleLayout>
      ),
    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
