export const yearsOfExperience = [
  { label: 'Less than 1 year' },
  { label: '1-2 years' },
  { label: '3-5 years' },
  { label: '6-10 years' },
  { label: '11-15 years' },
  { label: '16-20 years' },
  { label: '21-25 years' },
  { label: '26-30 years' },
  { label: 'More than 30 years' },
  { label: 'Prefer not to say' },
];
