import PropTypes from 'prop-types';
import { Stack, IconButton, Typography, Box } from '@mui/material';

import Iconify from 'src/components/iconify';

import { SimpleDialog } from 'src/components/custom-dialog';

import TorchIcon from './torch-icon';

export default function RewardMarketplaceWindow({ confirm, points }) {
  const rewardPoints = points;

  const marketplaceTitle = (
    <Stack
      direction="row"
      alignItems="left"
      justifyItems="center"
      justifyContent="space-between"
      sx={{
        whiteSpace: 'nowrap',
        typography: 'caption',
        width: '100%',
        py: 1,
      }}
    >
      <Typography variant="h6" color="text.primary" sx={{ alignSelf: 'center' }}>
        Marketplace
      </Typography>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" mr={1}>
          <TorchIcon size={25} sx={{ pl: '1' }} />
          <Typography variant="body2" color="text.primary">
            {rewardPoints}
          </Typography>
        </Stack>
        <IconButton color="inherit" mx={2} onClick={confirm.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      </Stack>
    </Stack>
  );

  const marketplaceContent = (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '200px',
      }}
    >
      <Typography color="text.secondary">Marketplace is coming soon...</Typography>
    </Box>
  );

  return (
    <SimpleDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title={marketplaceTitle}
      content={marketplaceContent}
    />
  );
}

RewardMarketplaceWindow.propTypes = {
  confirm: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onFalse: PropTypes.func.isRequired,
  }).isRequired,
  points: PropTypes.number.isRequired,
};
