import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TorchIcon from './torch-icon'


export default function RewardIcon({ onClick, points }) {

    const  rewardPoints  = points

    return (

        <Stack direction="row" alignItems="center" px={1} py={1.5} spacing={2}>
            <IconButton 
                onClick={onClick} 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '12px', 
                }}>

                <TorchIcon size={42} alt="Torch Icon" sx={{ pl: '6px' }}/>

                <Typography noWrap variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: 12, fontWeight: 400 }}>
                    {rewardPoints}
                </Typography>

            </IconButton>
        </Stack>


    );
};

RewardIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
    points: PropTypes.number.isRequired,
  };
