import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useStore } from 'src/store';

import { useMockedUser } from 'src/hooks/use-mocked-user';
import { hideScroll } from 'src/theme/css';

import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';
import RewardIcon from 'src/components/reward/reward-nav-icon';
import { useBoolean } from 'src/hooks/use-boolean';
import { RewardMarketplaceWindow } from 'src/components/reward';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import AccountPopover from '../common/account-popover';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useMockedUser();

  const navData = useNavData();

  const confirm = useBoolean();

  const rewardPoints = useStore((state) => state.rewardPoints); 

  return (
    <>
      <Box
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV.W_MINI },
        }}
      >
        <Stack
          sx={{
            pb: 2,
            height: 1,
            position: 'fixed',
            width: NAV.W_MINI,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
            ...hideScroll.x,
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'auto',
          }}
        >
          <Box>
            <Logo sx={{ mx: 'auto', my: 2, display: 'flex', justifyContent: 'center' }} />
            <NavSectionMini
              data={navData}
              slotProps={{
                currentRole: user?.role,
              }}
            />
          </Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <RewardIcon onClick={confirm.onTrue} points={rewardPoints} />
            <AccountPopover />
          </div>
        </Stack>
      </Box>

      <RewardMarketplaceWindow confirm={confirm} points={rewardPoints} />
    </>
  );
}
