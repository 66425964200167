export const coachingStyle = [
  { label: 'Transformational Coaching' },
  { label: 'Confronting Coaching' },
  { label: 'Ontological Coaching' },
  { label: 'Solution-Focused Coaching' },
  { label: 'Narrative Coaching' },
  { label: 'Positive Psychology Coaching' },
  { label: 'Neuro-Linguistic Programming (NLP) Coaching' },
  { label: 'Team Coaching' },
  { label: 'Mindfulness Coaching' },
  { label: 'Other' },
];
