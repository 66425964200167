import PropTypes from 'prop-types';

import Header from '../common/header-simple';

// ----------------------------------------------------------------------

export default function SimpleLayout({ disabledLink, isOnboarding, children }) {
  return (
    <>
      <Header disabledLink isOnboarding/>

      {children}
    </>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
  disabledLink: PropTypes.bool,
  isOnboarding: PropTypes.bool,
};
