import React from 'react';
import { Box, Link, Typography, Container } from '@mui/material';

import Logo from 'src/components/logo';

const footerTextStyle = {
  color: '#6F6F6F',
  display: 'block',
  fontSize: 15,
  mb: 0.5,
};

const sectionHeaderStyle = {
  color: 'white',
  fontSize: 19,
  fontWeight: 500,
  mb: 2,
};

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        bgcolor: 'black',
        color: 'white',
        textAlign: 'center',
        fontFamily: 'SF Pro',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          pb: 1,
        }}
      >
        <Box sx={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
          <Box
            component="img"
            src="/assets/images/home/footer-logo.svg"
            alt="Footer Slogan"
            sx={{
              mb: 2,
              maxWidth: 80,
              height: 'auto',
              margin: { xs: 'auto', md: 0 },
            }}
          />
          <Box
            component="img"
            src="/assets/images/home/footer-slogan.svg"
            alt="Footer Slogan"
            sx={{
              mb: 6,
              mt: 2,
              maxWidth: { lg: 580, md: 400 },
              height: 'auto',
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}
          />
          <Typography
            sx={{ mt: 1, ...footerTextStyle, color: 'white', marginBottom: { md: 0, xs: 2 } }}
          >
            © 2024. All rights reserved
          </Typography>
        </Box>

        <Box>
          <Typography sx={sectionHeaderStyle}>Contact us</Typography>
          <Link href="mailto:lei@realix.ai" sx={footerTextStyle}>
            lei@realix.ai
          </Link>
          <Link href="tel:+1773236906" sx={footerTextStyle}>
            (+1) 773-236-3906
          </Link>
          <Link
            href="https://docs.google.com/document/d/1o_E8k-29KdJeUqpxNcwe62zdkGrRIbVixQmNdshYvVo/edit?usp=sharing"
            sx={{ ...footerTextStyle, mt: { md: 4, sm: 2 } }}
          >
            Privacy Policy
          </Link>
        </Box>

        <Box>
          <Typography sx={sectionHeaderStyle}>Online support</Typography>
          <Link
            href="https://www.linkedin.com/company/realixai/"
            target="_blank"
            rel="noopener"
            sx={footerTextStyle}
          >
            Linkedin
          </Link>
          <Link
            href="https://docs.google.com/document/d/19E6laMi6zo6jKr5uzHorwbskH3HyF9LAerTOEd__FK8/edit?usp=sharing"
            sx={{ ...footerTextStyle, mt: { md: '59px', sm: 2 } }}
          >
            Terms & Conditions
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
