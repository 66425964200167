// src/store/rewardSlice.js

const createRewardSlice = (set) => ({
  rewardPoints: 0,
  lastLoginTime: 0,

  checkAndApplyDailyReward: async (userId, confirm, getRewardData, postRewardData) => {
    try {
      const result = await getRewardData(userId);

      if (result && Object.keys(result).length !== 0 && result.constructor === Object) {
        const oldRewardPoints = result.rewardPoints ?? 0;
        const oldLoginTime = result.lastLoginTime ?? 0;

        set({ rewardPoints: oldRewardPoints });

        const d = new Date();
        d.setHours(0, 0, 0, 0);
        const midNightTime = d.getTime();
        const computedTime = Math.floor(midNightTime / 1000);

        if (oldLoginTime < computedTime) {
          const newRewardPoints = oldRewardPoints + 50;

          set({
            rewardPoints: newRewardPoints,
            lastLoginTime: Math.floor(Date.now() / 1000),
          });
          const rewardPayload = {
            userId,
            lastLoginTime: Math.floor(Date.now() / 1000),
            rewardPoints: newRewardPoints,
          };

          await postRewardData(rewardPayload);
          confirm.onTrue();
        }
      }
    } catch (error) {
      console.error('Failed to fetch reward data', error);
    }
  },

  // Reset the reward state to the initial values
  resetReward: () => set(() => ({
    rewardPoints: 0,
    lastLoginTime: 0,
  })),

});

export default createRewardSlice;
