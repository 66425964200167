// src/store/useStore.js

import { create } from 'zustand';

import createRewardSlice from './rewardSlice';

const useStore = create((set) => ({
  ...createRewardSlice(set),
}));

export default useStore;