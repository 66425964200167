import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { bgGradient } from 'src/theme/css';
import { useAuthContext } from 'src/auth/hooks';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

const METHODS = [
  {
    id: 'jwt',
    label: 'Jwt',
    path: paths.auth.jwt.login,
    icon: '/assets/icons/auth/ic_jwt.svg',
  },
  {
    id: 'firebase',
    label: 'Firebase',
    path: paths.auth.firebase.login,
    icon: '/assets/icons/auth/ic_firebase.svg',
  },
  {
    id: 'amplify',
    label: 'Amplify',
    path: paths.auth.amplify.login,
    icon: '/assets/icons/auth/ic_amplify.svg',
  },
  {
    id: 'auth0',
    label: 'Auth0',
    path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/ic_auth0.svg',
  },
  {
    id: 'supabase',
    label: 'Supabase',
    path: paths.auth.supabase.login,
    icon: '/assets/icons/auth/ic_supabase.svg',
  },
];

export default function AuthClassicLayout({ children, image, title }) {
  const { method } = useAuthContext();

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const renderLogo = (
    <Box
    sx={{
      zIndex: 9,
      display: 'flex',
      pt: 5,
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Logo/>
    </Box>

  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        height: '85vh',
        mx: 'auto',
        maxWidth: 560,
        px: { xs: 2, md: 8 },
        pt: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          padding: '30px',
          borderRadius: 3,
          borderWidth: 0.5,
          borderColor: '#fafafa',
          borderStyle: 'solid',
          backdropFilter: 'blur(10px)', 
        }}>
      {children}
      </Box>

    </Stack>
  );

  // const renderSection = (
  //   <Stack
  //     flexGrow={1}
  //     spacing={10}
  //     alignItems="center"
  //     justifyContent="center"
  //     sx={{
  //       ...bgGradient({
  //         color: alpha(
  //           theme.palette.background.default,
  //           theme.palette.mode === 'light' ? 0.88 : 0.94
  //         ),
  //         imgUrl: '/assets/background/overlay_2.jpg',
  //       }),
  //     }}
  //   >
  //     <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
  //       {title || 'Hi, Welcome back'}
  //     </Typography>

  //     <Box
  //       component="img"
  //       alt="auth"
  //       src={image || '/assets/illustrations/illustration_dashboard.png'}
  //       sx={{
  //         maxWidth: {
  //           xs: 480,
  //           lg: 560,
  //           xl: 720,
  //         },
  //       }}
  //     />

  //     <Stack direction="row" spacing={2}>
  //       {METHODS.map((option) => (
  //         <Tooltip key={option.label} title={option.label}>
  //           <Link component={RouterLink} href={option.path}>
  //             <Box
  //               component="img"
  //               alt={option.label}
  //               src={option.icon}
  //               sx={{
  //                 width: 32,
  //                 height: 32,
  //                 ...(method !== option.id && {
  //                   filter: 'grayscale(100%)',
  //                 }),
  //               }}
  //             />
  //           </Link>
  //         </Tooltip>
  //       ))}
  //     </Stack>
  //   </Stack>
  // );

  return (

    <Stack
      component="main"
      direction="column"
      sx={{
        minHeight: '100vh',
        width: '100vw',
        backgroundImage: `url('/assets/images/home/login-background-1.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      {renderLogo}

      {mdUp}

      {renderContent}
    </Stack>

  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
