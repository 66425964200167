import React from 'react';
import { IconButton, Stack, Button, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';

import { SimpleDialog } from 'src/components/custom-dialog';

import TorchIcon from './torch-icon';

export default function RewardLoginWindow({ confirm }) {
  const handleClose = () => {
    confirm.onFalse();
  };

  const windowStyle = {
    numberColor: '#FF8B2D',
    bgColor: '#FFF7F0',
  };

  const rewardLoginTitle = (
    <Stack
      direction="row"
      alignItems="left"
      justifyItems="center"
      justifyContent="space-between"
      sx={{
        whiteSpace: 'nowrap',
        typography: 'caption',
        width: '100%',
      }}
    >
      <Typography variant="subtitle1" color="text.primary" pt={1}>
        Daily Bonus
      </Typography>
      <IconButton color="inherit" edge="end" onClick={handleClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const rewardLoginContent = (
    <Box textAlign="center">
      <TorchIcon size={90} sx={{ pl: '10px' }} />
      <Typography variant="h6" gutterBottom color="text.primary">
        Welcome Back!
      </Typography>
      <Typography variant="body2" gutterBottom color="text.secondary">
        Come back every day to claim a new reward!
      </Typography>
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: windowStyle.bgColor,
          mt: 1,
          p: 1,
          width: '100%',
        }}
      >
        <Typography variant="body2">
          You&apos;re given
          <strong style={{ color: windowStyle.numberColor }}> 50</strong>
          <TorchIcon size={28} sx={{ pl: '0' }} />
          rewards
        </Typography>
      </Box>
    </Box>
  );

  const rewardLoginAction = (
    <Button
      onClick={handleClose}
      variant="contained"
      sx={{
        bgcolor: 'grey.800',
        color: 'common.white',
      }}
    >
      Keep going
    </Button>
  );

  return (
    <SimpleDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title={rewardLoginTitle}
      content={rewardLoginContent}
      action={rewardLoginAction}
    />
  );
}

RewardLoginWindow.propTypes = {
  confirm: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onFalse: PropTypes.func.isRequired,
  }).isRequired,
};
