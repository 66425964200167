import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const TorchIcon = ({ size, sx }) => (
    <Box
        component="img"
        src="/assets/icons/navbar/ic_torch.svg"
        alt="torch icon"
        sx={{ 
            width: size, 
            height: size,
            justifyContent: 'center',
            alignItems: 'center',
            ...sx
        }}
    />
);

export default TorchIcon;

TorchIcon.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sx: PropTypes.object,
};


