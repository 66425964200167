export const industries = [
  { label: 'Agriculture, Forestry, Fishing and Hunting' },
  { label: 'Arts, Entertainment, and Recreation' },
  { label: 'Construction' },
  { label: 'Education' },
  { label: 'Finance and Insurance' },
  { label: 'Health Care and Social Assistance' },
  { label: 'Hospitality (Hotel and Food Services)' },
  { label: 'Information Technology and Communications' },
  { label: 'Manufacturing' },
  { label: 'Mining, Quarrying, and Oil and Gas Extraction' },
  { label: 'Professional, Scientific, and Technical Services' },
  { label: 'Public Administration/Government' },
  { label: 'Real Estate, Rental and Leasing' },
  { label: 'Retail Trade' },
  { label: 'Transportation and Warehousing' },
  { label: 'Utilities' },
  { label: 'Wholesale Trade' },
  { label: 'Nonprofit/Charity' },
  { label: 'Self-Employed/Entrepreneur' },
  { label: 'Student' },
  { label: 'Unemployed' },
  { label: 'Other' },
];
