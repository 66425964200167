export const genders = [
  { label: 'Male' },
  { label: 'Female' },
  { label: 'Transgender Male' },
  { label: 'Transgender Female' },
  { label: 'Non-Binary' },
  { label: 'Genderqueer' },
  { label: 'Genderfluid' },
  { label: 'Agender' },
  { label: 'Bigender' },
  { label: 'Two-Spirit' },
  { label: 'Prefer not to say' },
  { label: 'Other' },
];
